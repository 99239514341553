// src/components/SplashScreen.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define the animation keyframes
const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const SplashScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#3f51b5',
        color: 'white',
        animation: `${fadeInScale} 2s ease-in-out`,
      }}
    >
      <Typography
        variant="h2"
        sx={{ mb: 2, animation: `${fadeInScale} 1.5s ease-in-out` }}
      >
        Card Choice Pro
      </Typography>
      <Typography
        variant="h6"
        sx={{ animation: `${fadeInScale} 2s ease-in-out` }}
      >
        Choose Wisely, Spend Smartly
      </Typography>
    </Box>
  );
};

export default SplashScreen;
