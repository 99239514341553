import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
  Chip,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const CreditCardForm = () => {
  const [countries, setCountries] = useState(['US', 'India']);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [purchaseType, setPurchaseType] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('INR');
  const [purpose, setPurpose] = useState('');
  const [recommendation, setRecommendation] = useState(null);

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    if (selectedCountries.length > 0) {
      setSelectedBanks([]);
      setBanks([]);
      setSelectedCards([]);
      setCards([]);
      setRecommendation(null);

      axios
        .post(
          'https://asia-south1-card-choice-pro-o1g7rv.cloudfunctions.net/cardchoicepro-genai/getlistbank',
          {
            countries: selectedCountries,
          },
          axiosConfig
        )
        .then((response) => {
          setBanks(response.data.banks);
        })
        .catch((error) => {
          console.error('Error fetching banks:', error);
        });
    } else {
      setBanks([]);
      setSelectedBanks([]);
      setCards([]);
      setSelectedCards([]);
    }
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedBanks.length > 0) {
      setSelectedCards([]);
      setCards([]);
      setRecommendation(null);

      axios
        .post(
          'https://asia-south1-card-choice-pro-o1g7rv.cloudfunctions.net/cardchoicepro-genai/getcards',
          {
            countries: selectedCountries,
            banks: selectedBanks,
          },
          axiosConfig
        )
        .then((response) => {
          setCards(response.data.cards);
        })
        .catch((error) => {
          console.error('Error fetching cards:', error);
        });
    } else {
      setCards([]);
      setSelectedCards([]);
    }
  }, [selectedBanks]);

  const isFormComplete = () => {
    return (
      selectedCountries.length > 0 &&
      selectedBanks.length > 0 &&
      selectedCards.length > 0 &&
      purchaseType &&
      amount &&
      currency &&
      purpose
    );
  };

  const handleSubmit = () => {
    const payload = {
      amount,
      currency,
      type: purchaseType,
      purpose,
      cards: selectedCards,
    };

    axios
      .post(
        'https://asia-south1-card-choice-pro-o1g7rv.cloudfunctions.net/cardchoicepro-genai/generate',
        payload,
        axiosConfig
      )
      .then((response) => {
        setRecommendation(response.data);
      })
      .catch((error) => {
        console.error('Error generating recommendation:', error);
      });
  };

  const renderTable = (cardsWithRewards) => (
    <TableContainer component={Paper} sx={{ mt: 2, backgroundColor: '#f1f1f1' }}>
      <Table aria-label="rewards table">
        <TableHead sx={{ backgroundColor: '#3f51b5' }}>
          <TableRow>
            <TableCell sx={{ color: 'white' }}>Name</TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Reward
            </TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Reward Percentage
            </TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Reward Amount
            </TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Currency
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cardsWithRewards.map((card) => (
            <TableRow key={card.name}>
              <TableCell component="th" scope="row">
                {card.name}
              </TableCell>
              <TableCell align="right">{card.reward}</TableCell>
              <TableCell align="right">{card.reward_percentage}</TableCell>
              <TableCell align="right">{card.reward_amount}</TableCell>
              <TableCell align="right">{card.currency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box
      sx={{
        p: 3,
        minHeight: '100vh',
        backgroundImage: 'url(https://source.unsplash.com/random/1920x1080?nature,abstract)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          borderRadius: 2,
          p: 3,
          boxShadow: 3,
          maxWidth: 600,
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 3, textAlign: 'center', color: '#3f51b5' }}
        >
          Choose Your Credit Card
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Country</InputLabel>
          <Select
            multiple
            value={selectedCountries}
            onChange={(e) => setSelectedCountries(e.target.value)}
            input={<OutlinedInput label="Select Country" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{ backgroundColor: '#3f51b5', color: 'white' }} />
                ))}
              </Box>
            )}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }} disabled={!banks.length}>
          <InputLabel>Select Banks</InputLabel>
          <Select
            multiple
            value={selectedBanks}
            onChange={(e) => setSelectedBanks(e.target.value)}
            input={<OutlinedInput label="Select Banks" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{ backgroundColor: '#3f51b5', color: 'white' }} />
                ))}
              </Box>
            )}
          >
            {banks.map((bank) => (
              <MenuItem key={bank} value={bank}>
                {bank}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }} disabled={!cards.length}>
          <InputLabel>Select Cards</InputLabel>
          <Select
            multiple
            value={selectedCards}
            onChange={(e) => setSelectedCards(e.target.value)}
            input={<OutlinedInput label="Select Cards" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{ backgroundColor: '#3f51b5', color: 'white' }} />
                ))}
              </Box>
            )}
          >
            {cards.map((card) => (
              <MenuItem key={card} value={card}>
                {card}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Type of Purchase</InputLabel>
          <Select
            value={purchaseType}
            onChange={(e) => setPurchaseType(e.target.value)}
          >
            <MenuItem value="offline">Offline</MenuItem>
            <MenuItem value="online">Online</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Currency</InputLabel>
          <Select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <MenuItem value="INR">INR</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Purpose"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormComplete()}
          sx={{ mb: 3 }}
        >
          Recommend Cards
        </Button>

        {recommendation && (
          <Box>
            <Typography variant="h6" sx={{ mb: 2, color: '#3f51b5' }}>
              Recommended Card
            </Typography>
            <Card variant="outlined" sx={{ mb: 3, backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="secondary">
                  {recommendation.json_response.analysis.best_card.name}
                </Typography>
                <Typography variant="body2">
                  {recommendation.json_response.analysis.best_card.reason}
                </Typography>
              </CardContent>
            </Card>

            <Typography variant="h6" sx={{ mb: 2, color: '#3f51b5' }}>
              Cards with Rewards
            </Typography>
            {renderTable(recommendation.json_response.analysis.cards_with_rewards)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CreditCardForm;
