// src/components/Recommendation.js
import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

const Recommendation = ({ recommendation }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Recommended Card
      </Typography>

      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6">
            {recommendation.json_response.analysis.best_card.name}
          </Typography>
          <Typography>
            {recommendation.json_response.analysis.best_card.reason}
          </Typography>
        </CardContent>
      </Card>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Other Cards
      </Typography>

      <Box
        sx={{ overflowX: 'auto' }}
        dangerouslySetInnerHTML={{ __html: recommendation.html_table }}
      />
    </Box>
  );
};

export default Recommendation;
