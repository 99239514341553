// src/App.js
import React, { useState, useEffect } from 'react';
import SplashScreen from './components/SplashScreen';
import CreditCardForm from './components/CreditCardForm';
import Recommendation from './components/Recommendation';
import axios from 'axios';
import { Container, Fade } from '@mui/material';

function App() {
  const [loading, setLoading] = useState(true);
  const [recommendation, setRecommendation] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleFormSubmit = (payload) => {
    axios
      .post(
        'https://asia-south1-card-choice-pro-o1g7rv.cloudfunctions.net/cardchoicepro-genai/generate',
        payload
      )
      .then((response) => {
        setRecommendation(response.data);
      });
  };

  return (
    <Container>
      <Fade in={loading}>
        <div>{loading && <SplashScreen />}</div>
      </Fade>
      <Fade in={!loading && !recommendation}>
        <div>{!loading && !recommendation && <CreditCardForm onSubmit={handleFormSubmit} />}</div>
      </Fade>
      <Fade in={!!recommendation}>
        <div>{recommendation && <Recommendation recommendation={recommendation} />}</div>
      </Fade>
    </Container>
  );
}

export default App;
